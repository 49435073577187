import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { notifyError } from "../../utils/Toaster";
import { Mic, StopCircle } from "lucide-react";
import { fetchTextChat, fetchSpeechChat } from "../../utils/directrequests";
import Tooltip from "../UI/Tooltip";
import { useParams } from "react-router-dom";

const ASSISSTANT = {
  sofia: "sofia",
  alex: "alex",
};

const QueryBar = (props) => {
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [respAudio, setRespAudio] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [stopGenerating, setStopGenerating] = useState(false);
  const [recordState, setRecordState] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const inputRef = useRef(null);
  const { botId } = useParams();

  useEffect(() => {
    const elements = document.querySelectorAll(".audio-react-recorder ");

    if (isRecording) {
      elements.forEach((element) => {
        element.classList.remove("recorder-hidden");
      });
    } else {
      elements.forEach((element) => {
        element.classList.add("recorder-hidden");
      });
    }
  }, [isRecording]);

  useEffect(() => {
    if (!props?.isSpeaking) {
      moveCursor();
    }
  }, [props?.isSpeaking]);

  const handleAudioDiscard = () => {
    setRecordedBlob(null);
    setAudioUrl(null);
  };

  const recordingStart = () => {
    // remove audion element
    handleAudioDiscard();
    setIsRecording(true);
    setRecordState(RecordState.START);
  };

  const recordingStop = () => {
    setIsRecording(false);
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const recordingOnStop = (audioData) => {
    console.log("audioData", audioData.blob);
    setRecordedBlob(audioData.blob);
    const url = URL.createObjectURL(audioData.blob);
    setAudioUrl(url);
  };

  // Function to move cursor inside the input
  const moveCursor = () => {
    // Check if the input element reference exists
    if (inputRef.current) {
      // Move cursor to the end of the input value
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    // Create an audio element
    const audio = new Audio();
    if (respAudio) {
      props?.setIsSpeaking(true);
      // Set the source of the audio from base64 data
      audio.src = `data:audio/mpeg;base64,${respAudio}`;

      // Play the audio using promises
      audio
        .play()
        .then(() => {
          // Calculate the total duration of the audio in milliseconds
          const audioDuration = (audio.duration || 0) * 1000;

          // Set a timeout to reset isSpeaking after the audio duration
          setTimeout(() => {
            props?.setIsSpeaking(false);
          }, audioDuration);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          props?.setIsSpeaking(false);
        });
    }

    // Clean up after component unmounts
    return () => {
      audio.pause(); // Pause the audio
      audio.src = ""; // Clear the source to release memory
      audio.load(); // Load an empty audio to reset the player
    };
  }, [respAudio]); // Re-run effect when base64Audio changes

  const handleInputChange = (event) => {
    // if previously intruptted generation then reset it
    setStopGenerating(false);

    const value = event.target.value;
    setUserQuery(value);
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)

    if (event.keyCode === 13) {
      // Check if the Shift key is also pressed
      if (!event.shiftKey) {
        event.preventDefault(); // Prevent the default Enter behavior (new line)
        beforeSubmit(); // Call your function here
      }
    }
  };

  const beforeSubmit = () => {
    if (_.isEmpty(userQuery) && _.isEmpty(audioUrl)) {
      notifyError("Please type/ record a query");
      return;
    }

    let type = 1;

    if (!_.isEmpty(audioUrl)) {
      type = 2;
    }

    // Set User Entry
    props?.getUserInput(
      type === 1 ? "text" : "audio",
      type === 1 ? userQuery : audioUrl
    );

    // Set query to empty string to free up input box
    setUserQuery("");

    // remove audion element
    handleAudioDiscard();

    // set previous response of model to empty string
    // props?.setResponse("");

    // call for submit function
    handleSubmit(type);
  };

  const handleSubmit = async (type) => {
    setIsGenerating(true);
    props.setGeneratingResponse(true)
    try {
      let response = {};

      if (type === 1) {
        const req = {
          user_input: userQuery,
          assistant_name: ASSISSTANT[botId],
        };
        response = await fetchTextChat(req);
      } else {
        const formData = new FormData();
        formData.append("file", recordedBlob, "recording.wav");
        formData.append("assistant_name", ASSISSTANT[botId]);
        response = await fetchSpeechChat(formData);
      }

      if (response.status === 200 && !stopGenerating) {
        const text_resp = response.data.text_response;
        let audio_base64 = "";
        if (type === 1) audio_base64 = response.data.audio_response;
        else audio_base64 = response.data.assistant_audio;
        setRespAudio(audio_base64);
        props?.setResponse(text_resp);
      }
    } catch (error) {
      console.log(error);
      props?.setResponse("Something went wrong, Please try again!")
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        // dispatch(setUser(null));
        // navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    }
    props.setGeneratingResponse(false)
    setIsGenerating(false);
  };

  const handleCancel = async () => {
    setIsGenerating(false);
    props?.setGeneratingResponse(false)
    props?.setIsSpeaking(false);
    setStopGenerating(true);
    //code goes here
  };

  return (
    <div className="flex justify-center w-full px-4 flex-col gap-3">
      <div className="flex border-custom rounded-lg px-6 py-2.5 bg-transparent gap-4 w-full align-middle">
        <div className="flex-vertical-center">
          <AudioReactRecorder
            backgroundColor="rgb(255,255,255,255)"
            foregroundColor="rgb(14, 70, 163)"
            canvasHeight={30}
            canvasWidth={100}
            state={recordState}
            onStop={recordingOnStop}
          />

          {!isRecording ? (
            <Tooltip message={"Start Recording!"}>
              <button
                onClick={recordingStart}
                className="record-icon box-shadow"
              >
                <Mic strokeWidth={1} size={20} color="#0E46A3" />
              </button>
            </Tooltip>
          ) : (
            <Tooltip message={"Stop Recording!"}>
              <button onClick={recordingStop}>
                <StopCircle size={28} color="#0e46a3" />
              </button>
            </Tooltip>
          )}
        </div>
        {audioUrl ? (
          <div className="flex-vertical-center gap-4 w-full">
            <audio
              className="box-shadow"
              style={{ borderRadius: "2rem" }}
              controls
            >
              <source src={audioUrl} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
            <Tooltip message={"Discard Recording!"}>
              <button
                className="delete-icon box-shadow"
                onClick={handleAudioDiscard}
              >
                <svg
                  fill="#ff0505"
                  width="15px"
                  height="15px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ff0505"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"></path>
                  </g>
                </svg>
              </button>
            </Tooltip>
          </div>
        ) : (
          <input
            type="text"
            ref={inputRef}
            value={userQuery}
            className="w-full"
            onChange={handleInputChange}
            placeholder="Type something..."
            onKeyDown={handleKeyPress}
            disabled={props?.isSpeaking ? true : isGenerating ? true : false}
          />
        )}
        <div className="flex">
          {props?.isSpeaking || isGenerating ? (
            <Tooltip message={"Stop Generating!"}>
              <button
                data-tooltip-id="tooltip-stop"
                className="cursor-pointer"
                onClick={handleCancel}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32px"
                  height="32px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#0e46a3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-circle-stop"
                >
                  <circle cx="12" cy="12" r="10" />
                  <rect width="6" height="6" x="9" y="9" />
                </svg>
              </button>
            </Tooltip>
          ) : (
            <Tooltip message={"Send!"}>
              <button className="" onClick={beforeSubmit}>
                <svg
                  width="32px"
                  height="32px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="rotate(90)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M17 12L10 12M10 12L13 15M10 12L13 9"
                      stroke="#0e46a3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>{" "}
                    <path
                      d="M7 16L7 12L7 8"
                      stroke="#0e46a3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    ></path>{" "}
                    <path
                      opacity="0.5"
                      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                      stroke="#0e46a3"
                      strokeWidth="1.5"
                    ></path>{" "}
                  </g>
                </svg>
              </button>
            </Tooltip>
          )}
        </div>
        {/* <button onClick={stopRecording}>Stop Recording</button> */}
      </div>
      <p
        style={{
          color: "color: #6a6a6a;",
        }}
        className="text-xs"
      >
        Note: Please keep your audio recordings under 5 seconds.
      </p>
    </div>
  );
};

export default QueryBar;
