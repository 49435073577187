import React, { useState } from "react";
import Dropdown from "./Common/Dropdown";
import { app_url } from "../utils/services";
import { useEffect } from "react";
import QueryBar from "./Common/QueryBar";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleCard from "./UI/SimpleCard";

function Card(props) {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const { botId } = useParams();

  useEffect(() => {
    // Play the video automatically only once after component mounts
    // if (props?.isChatPage) {
    //   videoRef.current.play();
    // }
    // Cleanup function to prevent memory leaks and unwanted behavior when the component unmounts
    // return () => {
    //   videoRef.current.pause();
    //   videoRef.current.currentTime = 0; // Reset the video playback position
    // };
  }, []);

  return (
    <div className="flex gap-4 p-4">
      <div
        className={`container custom-container ${props?.isChatPage ? "w-[30vw]" : "w-[50vw]"} drop-shadow-lg bg-white flex flex-col`}
      >
        {props?.isChatPage && (
          <i
            onClick={() => navigate("/")}
            className="absolute left-3 top-3 custom-color-4 cursor-pointer"
          >
            <ArrowLeft size={30} />
          </i>
        )}
        {!props?.isChatPage ? (
          <>
            <h1 className="flex justify-center text-white fs-2 fw-700">
              Select an Avatar
            </h1>
            <div className="px-4 py-2 rounded-lg h-full flex justify-center">
              <Dropdown />
            </div>
            <p className="text-white text-xs">
              Disclaimer: This project is for entertainment purposes only.
            </p>
          </>
        ) : (
          <div className="flex flex-col items-center w-full">
            {/* <video
          className="adjust-video"
          controlsList="nodownload"
          preload="auto"
          muted
          ref={videoRef}
          onClick={() => {
            // This click event handler is no longer needed since the video plays automatically
          }}
        >
          <source src={`${app_url}/video/IMG_2496.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
            <div className="h-[19rem] xl:h-[20rem]">
              <img
                style={{
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  visibility: isSpeaking ? "visible" : "hidden",
                  top: "4rem",
                  height: "75%",
                }}
                className="adjust-avatar"
                src={`${app_url}/video/${botId}.gif`}
                alt="avatar"
              />
              <img
                className="adjust-avatar"
                style={{
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  top: "4rem",
                  height: "75%",
                  visibility: isSpeaking ? "hidden" : "visible",
                }}
                src={`${app_url}/img/${botId}.png`}
                alt="avatar"
              />
            </div>
            {/* <QueryBar
              setResponse={setResponse}
              isSpeaking={isSpeaking}
              setIsSpeaking={setIsSpeaking}
            /> */}
          </div>
        )}
      </div>
      {props?.isChatPage && (
        <SimpleCard
          isSpeaking={isSpeaking}
          setIsSpeaking={setIsSpeaking}
          botId={botId}
        />
      )}
    </div>
  );
}

export default Card;
