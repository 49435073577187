import axios from 'axios';
import { app_url } from './services';
import {API_URL} from './services';
// /text-chat-elon
// /text-chat-indra
// /speech-chat-elon
// /speech-chat-indra

// Make a GET request to the /speech-chat endpoint

// ELON MUSK ENDPOINTS
const fetchSpeechChat = async (params) => {
  try {
    const response = await axios.post(`${app_url}/speech-chat`, params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    return response;
  } catch (error) {
    console.error('There was a problem with the request:', error);
  }
};

// Make a GET request to the /text-chat endpoint
const fetchTextChat = async (params) => {
  console.log("api url: ", app_url)
  try {
    const response = await axios.post(`${app_url}/text-chat`, params);
    return response;
  } catch (error) {
    console.error('There was a problem with the request:', error);
  }
};

export {
    fetchSpeechChat,
    fetchTextChat,
}